<template>
  <main class="page-container" id="sme-campaign">
    <div class="page-header">
      <div class="page-component">
        <div class="page-header-title">
          <h2 class="title">크리에이터 매칭 캠페인</h2>
        </div>
      </div>
    </div>
    <div class="page-body" data-fill="true">
      <nav class="page-nav">
        <ul class="nav-list page-component">
          <li class="nav-item gachi-cursor-pointer" :class="{'is-selected': (!$route.query.clsId || Number($route.query.clsId) === 0)}"
          >
            <router-link :to="{name:'Campaign'}" class="nav-link"><span class="nav-text">전체</span></router-link>
          </li>
          <li v-for="(category, idx) in categories" class="nav-item gachi-cursor-pointer" :class="{'is-selected': category.gdsClsId ===  Number($route.query.clsId)}" :key="idx">
            <router-link :to="{name:'Campaign', query:{clsId:category.gdsClsId}}"><span class="nav-text">{{ category.clsNm }}</span></router-link>
          </li>
        </ul>
      </nav>

      <common-list
          :class="['page-component']"
          :is-loading="isLoading"
          :is-no-result="isNoResult"
      >
        <div class="post-list-wrapper notice-list-wrapper" >
          <div class="list-header">
            <template v-if="isMobile">
              <div class="filter-selector">
                <select v-model="sortCd" name="" id="" class="form-select" required="" @change="selectSort">
                  <option value="">최신순</option>
                  <option value="D">마감임박순</option>
                </select>
              </div>
            </template>
            <template v-else>
              <div class="header-column">
                <h3 class="title">전체 <span class="text-muted">{{ totalCount }}</span></h3>
              </div>
              <div class="header-column">
                <div class="filter-selector">
                  <select v-model="sortCd" name="" id="" class="form-select" required="" @change="selectSort">
                    <option value="">최신순</option>
                    <option value="D">마감임박순</option>
                  </select>
                </div>
              </div>
            </template>
          </div>

          <!-- begin::post-card-list -->
          <common-card
              :items="refineItems"
              :is-tag="true"
              :has-more="hasMore"
          />

          <div v-if="hasMore" class="page-buttons pb-5" data-align="center">
            <button class="btn btn-lg btn-outline-white" @click="morePage">
              <span class="text">더보기</span>
            </button>
          </div>
          <!-- end::post-card-list -->
        </div>
      </common-list>
    </div>
  </main>
</template>

<script>
import {ACT_GET_CAMPAIGN_LIST, ACT_GET_CATEGORY_LIST} from '../../../store/_act_consts';
import {
  getCheckItems,
  getItems,
  getRegisterTimeFormat,
  lengthCheck,
  queryToValue,
  setPaging,
} from '../../../assets/js/utils';
import CommonCard from '../../../components/common/CommonCard';
import NoResult from '../../../components/common/NoResult';
import {mapGetters} from 'vuex';
import {MUT_SET_CATEGORY_LIST} from '../../../store/_mut_consts';
import CommonList from '../../../components/common/CommonList';

export default {
  name: 'Campaign',
  components: {
    CommonCard,
    NoResult,
    CommonList
  },
  data: () => ({
    isLoading: false,
    hasMore: false,
    totalCount: 0,
    pageSize: 12,
    pageNo: 1,
    sortCd: '',
    gdsClsId: 0,
    items: [],
    nowTimestamp: 0,
  }),
  watch:{
    $route(to){
      this.gdsClsId = queryToValue(to.query.clsId, true,0);
      this.selectCategory(this.gdsClsId);
    }
  },
  computed:{
    ...mapGetters('common', ['isMobile']),
    ...mapGetters('promotion', ['categories']),
    isNoResult(){
      return this.items.length === 0;
    },
    refineItems(){
      return this.items.map(x => ({
        ctgr: ((item) => {
          const filtered = this.categories.filter(f => item.gdsClsId === f.gdsClsId);
          if(filtered.length > 0) return filtered[0].clsNm;
          return '기타';
        })(x),
        tit: x.matchCampnNm,
        tags: this.getEntryTimeFormat(x),
        viewLink: (this.$route.query.clsId ? `/campaign/${x.matchCampnId}/view?clsId=${this.$route.query.clsId ? this.$route.query.clsId : 0}` : `/campaign/${x.matchCampnId}/view`),
        rprsImgPtUrl: x.rprsImgPt
      }));
    }
  },
  created() {
    this.nowTimestamp = new Date().getTime();
    if(this.categories.length === 0) this.getCategoryList();
    this.gdsClsId = queryToValue(this.$route.query.clsId, true,0);
    this.getCampaignList(true);
  },
  methods: {
    getCategoryList(){
      this.$store.dispatch(`promotion/${ACT_GET_CATEGORY_LIST}`, {clsLv: 1}).then(res => {
        this.$store.commit(`promotion/${MUT_SET_CATEGORY_LIST}`, getCheckItems(res));
      }).catch(e => {
        console.error(e);
      });
    },
    getCampaignList(isInit) {
      if(isInit) {
        this.items = [];
        this.isLoading = true;
        this.pageNo = 1;
      }
      this.$store.dispatch(`promotion/${ACT_GET_CAMPAIGN_LIST}`, {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        sortCd: this.sortCd,
        gdsClsId: this.gdsClsId
      }).then(res=>{
        if(lengthCheck(res)){
          this.items = this.items.concat(getItems(res));
          setPaging(this, res);
        } else {
          setPaging(this, res);
        }
        this.isLoading = false;
      }).catch(e=>{
        console.error(e);
        this.isLoading = false;
      })
    },
    morePage(){
      if(this.hasMore) this.pageNo++;
      this.getCampaignList();
    },
    selectSort() {
      this.getCampaignList(true);
    },
    getEntryTimeFormat(item){
      if(item.entryEndDt < this.nowTimestamp){
        return '신청 마감';
      }else{
        return `마감 ${getRegisterTimeFormat(this.nowTimestamp, item.entryEndDt)}`;
      }
    },
    selectCategory(gdsClsId){
      this.gdsClsId = gdsClsId;
      this.getCampaignList(true);
    }
  }
};
</script>
